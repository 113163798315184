<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Koin Setting
      </b-card-header>
      <b-card-body>
        <inventory></inventory>
      </b-card-body>
      <b-card-body>
          <div class="loading" v-if="isLoading == true">
              <div class="sk-three-bounce">
                  <div class="sk-child sk-bounce1"></div>
                  <div class="sk-child sk-bounce2"></div>
                  <div class="sk-child sk-bounce3"></div>
              </div>
          </div>
        <b-row>
          <b-col sm="8">
            <p style="font-weight:bold">Payment Method</p>
          </b-col>
          <b-col sm="4">
            <p style="font-weight:bold">Generate Koin Nusantara Price</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="8">
            <!--<h6>Simple Form</h6>-->
            <div>
              <b-form v-on:submit.prevent="savePayment" novalidate action="https://vuejs.org/">
                <b-row>
                  <b-col class="check" sm="6" v-for="(dinars, index) in dinar" :key="index">
                    <b-form-group>
                      <b-form-checkbox-group stacked id="basicCheckboxes" name="Checkboxes" :plain="true" v-model="dinarPayment">
                        <b-form-checkbox :plain="true" :value="dinars.payment_code">{{ dinars.payment_name }}</b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                </b-row><br>
                <b-button type="submit" variant="primary">
                  Submit
                </b-button>
              </b-form>
            </div>
          </b-col>
          <b-col sm="4">
            <div>
              <b-form v-on:submit.prevent="setTimeLock" novalidate>
                <b-form-group
                  class="mb-3"
                  label="Date :"
                  label-for="daterange"
                >
                  <b-input-group
                  >
                    <b-input-group-prepend is-text>
                      <i class="fa fa-calendar"></i>
                    </b-input-group-prepend>
                    <time-picker id="timepicker" :start="startDate" @picker="changeTime"></time-picker>
                    <b-input-group-append>
                      <b-button type="submit" variant="success" >Submit</b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <div  v-if="this.startDate == '' "  style="margin-top:10px">
                    <p id="dateFeedback" v-for="(error , index) in errors.form.time" :key="index">
                      - {{ error }} <br>
                    </p>
                  </div>
                </b-form-group>
              </b-form>
            </div>
            <b>Current Time : {{ this.basedTime }}</b>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Inventory from './DinarInventory'

export default {
  name: "dinar-setting",
  components: {
    Inventory,
  },
  data() {
    return {
      basedTime: '',
      startDate: '',
      payments: [],
      dinar: '',
      dinars: [],
      dinarPayment: [],
      row: [],
      splitselect: [],
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          time: [],
        },
      },
      isLoading: false
    }
  },
  created () {
      this.$http.get(`active-payment`)
      .then((result) => {
        this.row = result.data.data.length;
        var rowDetail = [];
        for (let index = 0; index < this.row; index++) {
          this.splitselect = result.data.data
          rowDetail.push(this.splitselect[index]);
        }
        this.dinarPayment = rowDetail
      }).catch((error) => {
        if (error.response) {
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
        }
      })

      this.$http.get(`dinar-payment`, {
        params: {
          payments: this.dinars
        }
      })
      .then((result) => {
        this.dinar = result.data.payments;
        this.basedTime = result.data.lock.time_lock;
      }).catch((error) => {
        if (error.response) {
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
        }
      })
  },
  methods: {
    changeTime (value) {
      this.startDate = value.startDate
    },
    savePayment() {
      this.isLoading = true
      this.$http.post(`save-payment`, {
          payment_code: this.dinarPayment
      }).then(() => {
        this.isLoading = false
          this.$swal.fire(
            'Success!',
            'Payment method saved successfully.',
            'success'
          ).then(() =>{
            location.reload()
          })
      }).catch((error) => {
        this.isLoading = false
        if (error.response) {
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
        }
      })
    },
    setTimeLock() {
      this.isLoading = true
      this.$http.post(`set-timelock`, {
        time_lock: this.startDate
      }).then(() => {
        this.isLoading = false
        this.$swal.fire(
          'Success!',
          'Time lock successfully set',
          'success'
        ).then(() =>{
          location.reload()
        })
      }).catch((error) => {
        this.isLoading = false
        if (error.response) {
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
          if(this.errors.status == 422) {
            this.$toasted.error('Please fill out the form that must be required')
            this.errors.message = error.response.data.meta.message;
            this.errors.form.time = this.errors.message.time_lock;
          }
        }
      })
    },
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}

.check {
  margin-top: 15px;
}

#dateFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
