<template>
  <div>
    <vuetable ref="vuetable"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData">

        <template slot="name-slot" slot-scope="prop">
          <span>{{ prop.rowData.name }}</span>
        </template>

        <template slot="sku-slot" slot-scope="prop">
          <span>{{ prop.rowData.sku }}</span>
        </template>
      </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"
      ></vuetable-pagination-info>

      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return {
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `inventory`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'name',
            sortField: 'name',
            direction: 'asc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'name-slot',
            title: 'Name'
          },
          {
            name: 'gram_per_unit',
            title: 'Gram Per Unit',
            titleClass: 'center aligned',
            dataClass: 'right aligned'
          },
          {
            name: 'unit',
            titleClass: 'center aligned',
            dataClass: 'center aligned'
          },
          {
            name: 'stock_out',
            titleClass: 'center aligned',
            dataClass: 'center aligned'
          },
          {
            name: 'sku-slot',
            title: 'SKU'
          },
        ]
      }
    },
    methods: {
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
    },
  }
</script>
